"use client";

import CookieConsent from "react-cookie-consent";
import Link from "next/link";
import { spaceRemoveWithLowerCase } from "@litonarefin/utils/spaceRemoveWithLowerCase";

const CookieNotice = ({ cookieURL }) => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept & Close"
            cookieName={`${spaceRemoveWithLowerCase(
                process.env.NEXT_PUBLIC_SOURCE_SITE
            )}-cookie-accept`}
            buttonStyle={{
                padding: "5px 25px",
                borderRadius: "4px",
                color: "#fff",
                background: "#0d49ce",
                margin: "10px 20px",
                fontSize: "12px",
            }}
            declineButtonStyle={{
                border: "1px solid var(--primary)",
                borderRadius: "4px",
                padding: "12px 80px",
                color: "#fff",
                background: "transparent",
                fontSize: "13px",
            }}
            style={{
                background: "rgba(0,0,0,1)",
                maxWidth: "900px",
                margin: "0 auto",
                right: "0",
                borderRadius: "4px",
                marginBottom: "24px",
                fontSize: "12px",
            }}
            expires={150}>
            We use cookies to give you the best experience including curated
            content, banners and suggestions{" "}
            <Link
                href={cookieURL || "#"}
                className="jlt-text-white jlt-underline">
                Privacy Policy
            </Link>
        </CookieConsent>
    );
};

export default CookieNotice;
