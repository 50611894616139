import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

export const cleanAndTransformBlocks = (blocksJSON) => {
    if (isEmpty(blocksJSON)) return [];

    const blocks = JSON.parse(blocksJSON);

    const assignId = (b) => {
        b.forEach((block) => {
            block.id = uuid();
            if (block?.innerBlocks?.length) {
                assignId(block.innerBlocks);
            }
        });
    };

    assignId(blocks);

    return blocks;
};
