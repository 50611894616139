import Image from "next/image";
import {
    Facebook,
    Instagram,
    Linkedin,
    Twitter,
    Youtube,
} from "@litonarefin/components/Icons";
import Link from "next/link";
import ReactAdminSubscribe from "@litonarefin/components/Footer/ReactAdminSubscribe";

function ReactAdminFooter({
    subscribeData,
    footerOne,
    footerTwo,
    footerThree,
}) {
    const year = new Date().getFullYear();

    return (
        <footer className={`jlt-bg-[#1D2939] jlt-mt-44`}>
            <div className="jlt-container jlt-max-w-[1280px] jlt-mx-auto jlt-px-8 jlt-relative jlt-pb-12 sm:jlt-pb-16 md:jlt-pb-28 jlt-pt-[290px]">
                <div className="jlt-absolute -jlt-top-[149px] jlt-left-0 jlt-w-full jlt-px-4 xl:jlt-px-0">
                    <div className="jlt-bg-white jlt-p-8 xl:jlt-p-[48px] jlt-rounded-2xl jlt-text-center jlt-flex jlt-flex-col jlt-items-center jlt-justify-center jlt-shadow-[0px_0px_48px_rgba(16,24,40,0.04)]">
                        <h3 className="jlt-text-[#1D2939] jlt-text-4xl jlt-leading-[48px] jlt-mb-2">
                            Subscribe our Newsletter
                        </h3>
                        <p className="jlt-text-[#667085] jlt-text-lg jlt-leading-6 jlt-font-medium">
                            Join our newsletter and be the first to know about
                            new product release, <br />
                            discounts and useful resources!
                        </p>

                        <ReactAdminSubscribe
                            listID={subscribeData?.listID}
                            tagID={subscribeData?.tagID}
                        />
                    </div>
                </div>

                <div className="jlt-grid jlt-gap-y-8 md:jlt-grid-cols-2 lg:jlt-grid-cols-5">
                    <div className="jlt-flex jlt-flex-col lg:jlt-col-span-2 jlt-text-center md:jlt-text-left lg:jlt-text-left jlt-items-center md:jlt-items-start lg:jlt-items-start">
                        <Image
                            src={"/white-logo.svg"}
                            alt={"Footer Logo"}
                            width={224}
                            height={36}
                            sizes={"100vw"}
                        />

                        <div className="jlt-flex jlt-text-center jlt-gap-x-2 jlt-mt-6">
                            <Facebook />
                            <Twitter />
                            <Youtube />
                            <Instagram />
                            <Linkedin />
                        </div>
                    </div>
                    <FooterMenu label="Products" menus={footerOne} />
                    <FooterMenu label="Supports" menus={footerTwo} />
                    <FooterMenu label="Company" menus={footerThree} />
                </div>
                <div className="jlt-flex jlt-flex-col md:jlt-flex-row jlt-justify-between jlt-items-center jlt-mt-24">
                    <p className="jlt-text-[#EAECF0] jlt-mb-4 md:jlt-mb-0 jlt-text-center md:jlt-text-left">
                        <Link
                            className="jlt-font-bold jlt-text-[#FB346D]"
                            href={"/"}>
                            {process.env.NEXT_PUBLIC_SOURCE_SITE}
                        </Link>
                        <span> &copy; 2014-{year} All rights reserved.</span>
                    </p>
                    <Image
                        src={"/payment.png"}
                        alt="Payment"
                        width={194}
                        height={24}
                        sizes={"100vw"}
                    />
                </div>
            </div>
            {/* <Fomo /> */}
        </footer>
    );
}

export default ReactAdminFooter;

const FooterMenu = ({ label = "", menus }) => {
    return (
        <div className="jlt-flex jlt-flex-col jlt-text-center md:jlt-text-left lg:text-left">
            <h3 className="jlt-text-[#fff] jlt-text-xl jlt-font-bold">
                {label}
            </h3>
            <div className="jlt-mt-8 ">
                <div className="jlt-flex jlt-flex-col jlt-items-center md:jlt-items-start jlt-gap-[10px]">
                    {menus?.map((item, idx) => (
                        <div
                            key={idx}
                            className="jlt-flex jlt-items-center jlt-gap-2">
                            {item?.img_url ? (
                                <div>
                                    <img
                                        src={item?.img_url}
                                        alt={item?.img_alt || "Product Logo"}
                                        width={24}
                                        height={24}
                                    />
                                </div>
                            ) : null}
                            {!!item?.menu_target ? (
                                <a
                                    className="jlt-text-base jlt-font-semibold jlt-text-[#EAECF0] jlt-inline-block hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                                    href={item?.menu_url || "/#"}
                                    target="_blank">
                                    {item?.menu_label}
                                </a>
                            ) : (
                                <Link
                                    className="jlt-text-base jlt-font-semibold jlt-text-[#EAECF0] jlt-inline-block hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                                    href={item?.menu_url || "/#"}>
                                    {item?.menu_label}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
