"use client";

import JewelthemeFooter from "@litonarefin/components/Footer/JewelthemeFooter";
import ReactAdminFooter from "@litonarefin/components/Footer/ReactAdminFooter";
import { memo } from "react";

const Footer = memo(({ subscribeData, footerOne, footerTwo, footerThree, socialShare = {} }) => {
    // const route = useRouter();
    // const path = route.pathname;

    return (
        <>
            {process.env.NEXT_PUBLIC_SOURCE_SITE === "React Admin" ? (
                <ReactAdminFooter {...{ subscribeData, footerOne, footerTwo, footerThree }} />
            ) : (
                <JewelthemeFooter
                    {...{ subscribeData, footerOne, footerTwo, footerThree, socialShare }}
                />
            )}
        </>
    );
});

export default Footer;
