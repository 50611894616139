"use client";

import { usePathname } from "next/navigation";
import { useState, useEffect } from "react";
import Popup from "@litonarefin/components/Popup/Popup";
import { Fragment } from "react";
import { isEmpty } from "lodash";
import { addMinutes } from "@litonarefin/utils/addMinutes";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";

const PopupLayout = ({ popupData }) => {
    const [popupPages, setPopupPages] = useState([]);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [random, setRandom] = useState(null);
    const [isTimeExit, setIsTimeExit] = useState(false);

    const siteSource = getSourceSite();

    const pathName = usePathname();
    const solidPathName = pathName.substring(1, pathName.length).split("/")?.[0];

    // popup close
    const exitPopup = (slg) => {
        let data = localStorage.getItem(`${siteSource}-exit-popup`);
        let expireData = localStorage.getItem(`${siteSource}-popup-expire`);
        let savedData = [];
        let expirePopup = {};

        //
        if (data && !JSON.parse(data).includes(slg)) {
            savedData = [...JSON.parse(data), slg];
        } else {
            savedData = [slg];
        }

        // expire date
        if (expireData && Object.keys(JSON.parse(expireData) || {}).length) {
            expirePopup = {
                ...JSON.parse(expireData),
                [slg]: addMinutes(new Date(), 10080) // 10080 minutes = 7 days
                    .getTime()
                    .toString()
                    .slice(0, -3),
            };
        } else {
            expirePopup = {
                [slg]: addMinutes(new Date(), 10080) // 10080 minutes = 7 days
                    .getTime()
                    .toString()
                    .slice(0, -3),
            };
        }

        // set data to localStorage
        localStorage.setItem(`${siteSource}-exit-popup`, JSON.stringify(savedData));
        localStorage.setItem(`${siteSource}-popup-expire`, JSON.stringify(expirePopup));

        setRandom(Math.ceil(Math.random() * 10));
        setIsOpenPopup(false);

        // full site popup show after 10min
        if (slg !== "full-site") {
            const date = new Date();
            const newDate = addMinutes(date, 5); // 5 minuite
            localStorage.setItem(`${siteSource}-sw-fpop-xt`, newDate.getTime());
        }
    };

    useEffect(() => {
        let storageTime = "";
        let currentTime = "";

        let exitPagesJSON = localStorage.getItem(`${siteSource}-exit-popup`);

        let exitPages;
        if (exitPagesJSON) {
            exitPages = JSON.parse(exitPagesJSON);
            setPopupPages(exitPages);
        }

        if (exitPages?.includes("full-site") || isEmpty(exitPages)) {
            return;
        }

        if (!exitPages?.includes("full-site") && !localStorage.getItem(`${siteSource}-sw-fpop-xt`)) {
            setIsTimeExit(true);
        }

        const interval = setInterval(() => {
            storageTime = localStorage.getItem(`${siteSource}-sw-fpop-xt`);
            currentTime = new Date().getTime();
            if (storageTime?.slice(0, -3) === currentTime.toString().slice(0, -3)) {
                setIsOpenPopup(false);
                setIsTimeExit(true);
            }
            if (storageTime?.slice(0, -3) < currentTime.toString().slice(0, -3)) {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [random]);

    useEffect(() => {
        let expireData = localStorage.getItem(`${siteSource}-popup-expire`);
        let exitPagesJSON = localStorage.getItem(`${siteSource}-exit-popup`);

        let exitPages;
        if (exitPagesJSON) {
            exitPages = JSON.parse(exitPagesJSON);
        }

        // remove expire popup
        if (expireData) {
            let exp = JSON.parse(expireData);
            let popupPages = exitPages || [];

            let newPopupPages = [...popupPages];

            for (const key in exp) {
                if (new Date().getTime().toString().slice(0, -3) > exp[key]) {
                    let idx = newPopupPages.indexOf(key);
                    if (idx !== -1) {
                        newPopupPages.splice(idx, 1);
                    }
                }
            }

            if (!isEmpty(newPopupPages)) {
                localStorage.setItem(`${siteSource}-exit-popup`, JSON.stringify(newPopupPages));
            }
        }
    }, []);

    // Scroll Parcentage
    const scrollParcentage = () => {
        let pos = document.documentElement.scrollTop;
        let calcHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrollVal = Math.round((pos * 100) / calcHeight);

        if (scrollVal >= 30) setIsOpenPopup(true);
    };

    useEffect(() => {
        window.onscroll = scrollParcentage;
    }, []);

    return (
        <Fragment>
            {!(popupPages || []).includes(solidPathName || "full-site") && isOpenPopup ? <Popup key={isOpenPopup} popupData={popupData} exitPopup={exitPopup} /> : null}

            {(popupPages || []).includes(solidPathName) && !(popupPages || []).includes("full-site") && isOpenPopup && isTimeExit ? (
                <Popup key={isOpenPopup} popupData={popupData} exitPopup={exitPopup} />
            ) : null}
        </Fragment>
    );
};

export default PopupLayout;
