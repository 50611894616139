import React from "react";
import { useReactCountdown } from "./useReactCountdown";
import Count from "./Count";

function Counters({ date }) {
    const { days, hours, minutes, seconds } = useReactCountdown(date);

    return (
        <div className="jlt-flex jlt-items-center jlt-gap-5">
            <Count count={days} label={"Days"} />
            <Count count={hours} label={"Hours"} />
            <Count count={minutes} label={"Min"} />
            <Count count={seconds} label={"Sec"} />
        </div>
    );
}

export default Counters;
