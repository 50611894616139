import { getIcon } from "@litonarefin/utils/icons";
import "./Popup.scss";
import { useEffect, useState, Fragment } from "react";
import { isEmpty } from "lodash";
import { BlockRenderer } from "../BlockRenderer";
import { cleanAndTransformBlocks } from "@litonarefin/utils/cleanAndTransformBlocks";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";
import { usePathname } from "next/navigation";

const Popup = ({ popupData, exitPopup }) => {
    const [popup, setPopup] = useState([]);
    const [individualPopup, setIndividualPopup] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const pathName = usePathname();
    const solidPathName = pathName.substring(1, pathName.length).split("/")?.[0];

    const siteSource = getSourceSite();

    useEffect(() => {
        let exitPagesJSON = localStorage.getItem(`${siteSource}-exit-popup`);
        let exitPages;
        if (exitPagesJSON) {
            exitPages = JSON.parse(exitPagesJSON);
        }

        if (!isEmpty(popupData)) {
            popupData?.map((item) => {
                let popupMeta = item?.[`${siteSource}PopupMeta`]?.popupMeta;

                if (solidPathName === popupMeta?.popupPage?.slug && popupMeta?.isPopup && popupMeta?.showPopupOn === "individual_product" && !exitPages?.includes(popupMeta?.popupPage?.slug)) {
                    if (!popupMeta?.startDate && !popupMeta?.endDate) {
                        setIsOpen(true);
                        return setIndividualPopup(item);
                    }

                    if (
                        popupMeta?.endDate &&
                        popupMeta?.startDate &&
                        new Date(popupMeta?.startDate).getTime().toString().slice(0, -3) < new Date().getTime().toString().slice(0, -3) &&
                        new Date().getTime().toString().slice(0, -3) < new Date(popupMeta?.endDate).getTime().toString().slice(0, -3)
                    ) {
                        setIsOpen(true);
                        return setIndividualPopup(item);
                    }

                    if (popupMeta?.startDate && !popupMeta?.endDate && new Date(popupMeta?.startDate).getTime().toString().slice(0, -3) < new Date().getTime().toString().slice(0, -3)) {
                        setIsOpen(true);
                        return setIndividualPopup(item);
                    }

                    if (popupMeta?.endDate && !popupMeta?.startDate && new Date(popupMeta?.endDate).getTime().toString().slice(0, -3) > new Date().getTime().toString().slice(0, -3)) {
                        setIsOpen(true);
                        return setIndividualPopup(item);
                    }
                }

                // full site
                if (popupMeta?.showPopupOn === "full_site" && popupMeta?.isPopup && !exitPages?.includes("full-site")) {
                    if (!popupMeta?.startDate && !popupMeta?.endDate) {
                        setIsOpen(true);
                        return setPopup(item);
                    }

                    if (
                        popupMeta?.endDate &&
                        popupMeta?.startDate &&
                        new Date(popupMeta?.startDate).getTime().toString().slice(0, -3) < new Date().getTime().toString().slice(0, -3) &&
                        new Date().getTime().toString().slice(0, -3) < new Date(popupMeta?.endDate).getTime().toString().slice(0, -3)
                    ) {
                        setIsOpen(true);
                        return setPopup(item);
                    }

                    if (popupMeta?.startDate && !popupMeta?.endDate && new Date(popupMeta?.startDate).getTime().toString().slice(0, -3) < new Date().getTime().toString().slice(0, -3)) {
                        setIsOpen(true);
                        return setPopup(item);
                    }

                    if (popupMeta?.endDate && !popupMeta?.startDate && new Date(popupMeta?.endDate).getTime().toString().slice(0, -3) > new Date().getTime().toString().slice(0, -3)) {
                        setIsOpen(true);
                        return setPopup(item);
                    }
                }
            });
        }
    }, [solidPathName, popupData]);

    return (
        <Fragment>
            {isOpen ? (
                <div className="jlt-popup">
                    <div className="jlt-popup-content">
                        <span
                            onClick={() => {
                                exitPopup(!isEmpty(individualPopup) ? individualPopup?.[`${siteSource}PopupMeta`]?.popupMeta?.popupPage?.slug : "full-site");
                                setIndividualPopup([]);
                            }}
                            className="jlt-popup-close">
                            {getIcon("close")}
                        </span>
                        <BlockRenderer blocks={!isEmpty(individualPopup) ? cleanAndTransformBlocks(individualPopup?.blocksJSON) : cleanAndTransformBlocks(popup?.blocksJSON)} />
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default Popup;
