import * as React from "react";
const SvgInstagram = (props) => (
  <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38} height={38} rx={19} fill="#E1306C" />
    <path
      d="M19.002 13.872A5.12 5.12 0 0 0 13.875 19a5.12 5.12 0 0 0 5.127 5.128A5.12 5.12 0 0 0 24.13 19a5.12 5.12 0 0 0-5.128-5.128Zm0 8.462A3.34 3.34 0 0 1 15.67 19a3.337 3.337 0 0 1 3.333-3.334A3.337 3.337 0 0 1 22.336 19a3.34 3.34 0 0 1-3.334 3.334Zm6.534-8.671c0 .665-.536 1.195-1.196 1.195a1.196 1.196 0 1 1 1.196-1.196Zm3.396 1.213c-.076-1.602-.442-3.02-1.616-4.19-1.169-1.17-2.588-1.535-4.19-1.616-1.651-.093-6.6-.093-8.252 0-1.598.076-3.017.442-4.19 1.611-1.174 1.17-1.536 2.589-1.616 4.19-.094 1.652-.094 6.601 0 8.253.076 1.602.442 3.02 1.616 4.19 1.173 1.17 2.588 1.535 4.19 1.616 1.651.093 6.6.093 8.252 0 1.602-.076 3.021-.442 4.19-1.616 1.17-1.17 1.536-2.588 1.616-4.19.094-1.652.094-6.596 0-8.248Zm-2.133 10.02a3.375 3.375 0 0 1-1.901 1.9c-1.317.523-4.44.402-5.896.402-1.455 0-4.583.116-5.895-.401a3.375 3.375 0 0 1-1.901-1.902c-.522-1.316-.402-4.44-.402-5.895 0-1.455-.116-4.583.402-5.895a3.375 3.375 0 0 1 1.9-1.902c1.317-.522 4.441-.401 5.896-.401s4.584-.116 5.896.402a3.375 3.375 0 0 1 1.9 1.9c.523 1.317.402 4.441.402 5.896s.12 4.583-.401 5.895Z"
      fill="#fff"
    />
  </svg>
);
export default SvgInstagram;
