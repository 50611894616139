"use client";

import axios from "axios";
import { handleToastr } from "@litonarefin/utils/handleToastr";

function WPAdminifySubscribe({ listID = "", tagID = "" }) {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let name = formData.get("name");
        let email = formData.get("email");
        name = name == null ? "" : name;

        let customFieldValues = {
            site_source: process.env.NEXT_PUBLIC_NEXTJS_SITE_URL,
        };

        let { data } = await axios.post("/api/subscribe/create", {
            first_name: name,
            email: email,
            lists: listID?.replaceAll(" ", "").split(",").map(Number),
            tags: tagID?.replaceAll(" ", "").split(",").map(Number),
            status: "yes",
            custom_values: customFieldValues,
        });

        if (data?.success) {
            let msg = "Subscribe Success!";
            handleToastr(msg, "success", 3000);
        } else {
            handleToastr(data?.message, "warning", 3000);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="jlt-mt-6 jlt-w-full jlt-gap-2 jlt-flex jlt-items-center jlt-justify-center"
        >
            <input
                placeholder="Email Address"
                name="email"
                required={true}
                className="jlt-py-3 jlt-px-[14px] jlt-text-base jlt-placeholder-[#98A2B3] jlt-border jlt-border-[
                    #F2F4F7] focus:jlt-outline-none jlt-w-full"
            />
            <button className="jlt-py-3 jlt-px-5 jlt-rounded jlt-bg-[--primary] jlt-text-base jlt-font-semibold jlt-text-white jlt-flex jlt-items-center jlt-gap-2">
                Subscribe{" "}
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_3788_7397)">
                        <path
                            d="M14.6666 1.33301L7.33331 8.66634"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M14.6667 1.33301L10 14.6663L7.33334 8.66634L1.33334 5.99967L14.6667 1.33301Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_3788_7397">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </form>
    );
}

export default WPAdminifySubscribe;
