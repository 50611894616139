export function getDateTime(date) {
    // April 30, 2023 00:00:00 need
    const allMonth = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const pm = [
        { 1: "13" },
        { 2: "14" },
        { 3: "15" },
        { 4: "16" },
        { 5: "17" },
        { 6: "18" },
        { 7: "19" },
        { 8: "20" },
        { 9: "21" },
        { 10: "22" },
        { 11: "23" },
        { 12: "00" },
    ];
    const timeArray = date?.split(" ");
    const dateArray = timeArray?.[0]?.split("/");
    const hourArray = timeArray?.[1]?.split(":");

    const getHour = (h, type = "am") => {
        if (type === "am") {
            return h;
        }

        let getH = "";
        const hour = pm.map((p) => {
            if (p[h]) {
                getH = p[h];
            }
        });

        if (getH) {
            return getH;
        }
    };

    const solidDateTime = `${allMonth[dateArray?.[1] - 1]} ${dateArray?.[0]}, ${
        dateArray?.[2]
    } ${getHour(hourArray?.[0], timeArray?.[2])}:${hourArray?.[1]}:00`;

    return solidDateTime;
}
