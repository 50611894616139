import { memo } from "react";

function Count({ count, label }) {
    return (
        <div className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
            <h2 className="jlt-text-white jlt-text-2xl jlt-font-semibold">{count}</h2>
            <p className="jlt-text-[#01ffdc] jlt-text-sm jlt-font-medium">{label}</p>
        </div>
    );
}

export default memo(Count);
