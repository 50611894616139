import React from "react";
import "./SimpleNotificationBar.scss";
import Link from "next/link";
import { getValidURL } from "@litonarefin/utils/getValidURL";

const SimpleNotificationBar = ({ notification }) => {
    return (
        <div className="jlt-notice-wrapper">
            <div className="jlt-notice-content">
                <p dangerouslySetInnerHTML={{ __html: notification?.content }} />
                {getValidURL(notification?.button_url) ? (
                    <a href={notification?.button_url} target="_blank">
                        {notification?.button_text}
                    </a>
                ) : (
                    <Link href={notification?.button_url}>{notification?.button_text}</Link>
                )}
            </div>
        </div>
    );
};

export default SimpleNotificationBar;
