import * as React from "react";
const SvgYoutube = (props) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3748_8166)">
            <path
                d="M15.6813 4.12328C15.5909 3.78289 15.4126 3.47223 15.1643 3.22239C14.9161 2.97254 14.6066 2.79228 14.2668 2.69964C13.0159 2.36328 8.01587 2.36328 8.01587 2.36328C8.01587 2.36328 3.01587 2.36328 1.76496 2.69964C1.42516 2.79228 1.11564 2.97254 0.867394 3.22239C0.619147 3.47223 0.440873 3.78289 0.350415 4.12328C0.0158692 5.37964 0.0158691 7.99964 0.0158691 7.99964C0.0158691 7.99964 0.0158692 10.6196 0.350415 11.876C0.440873 12.2164 0.619147 12.5271 0.867394 12.7769C1.11564 13.0267 1.42516 13.207 1.76496 13.2996C3.01587 13.636 8.01587 13.636 8.01587 13.636C8.01587 13.636 13.0159 13.636 14.2668 13.2996C14.6066 13.207 14.9161 13.0267 15.1643 12.7769C15.4126 12.5271 15.5909 12.2164 15.6813 11.876C16.0159 10.6196 16.0159 7.99964 16.0159 7.99964C16.0159 7.99964 16.0159 5.37964 15.6813 4.12328Z"
                fill="#FF0302"
            />
            <path
                d="M6.37956 10.3793V5.62109L10.5614 8.00018L6.37956 10.3793Z"
                fill="#FEFEFE"
            />
        </g>
        <defs>
            <clipPath id="clip0_3748_8166">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgYoutube;
