import { sanitize } from "@litonarefin/utils/miscellaneous";
// import Image from "next/image";
import Link from "next/link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState, Fragment, useEffect } from "react";
import { getIcon } from "@litonarefin/utils/icons";
import { getStyledIcon } from "@litonarefin/utils/getStyledIcon";
import { getDateTime } from "./getDateTime";
import Counters from "./Counters";
import SimpleNotificationBar from "./SimpleNotificationBar";

export const NotificationBar = ({ notification }) => {
    const [copiedText, setCopiedText] = useState({ copied: false });

    let dateToEndCountdown = getDateTime(notification?.notification_time);

    useEffect(() => {
        let interval;
        if (copiedText?.copied === true) {
            interval = setInterval(() => {
                setCopiedText({ copied: false });
            }, 3000);
        }
        return () => clearInterval(interval);
    }, [copiedText]);

    if (!notification?.background_image && !notification?.image) {
        return <SimpleNotificationBar notification={notification} />;
    }

    return (
        <div
            id="jlt-notifications"
            className="jlt-hidden lg:jlt-block jlt-relative jlt-bg-cover"
            style={{
                backgroundImage: `url(${notification?.background_image?.url})`,
            }}>
            <div className="jlt-w-container jlt-max-w-[1280px] jlt-h-24 jlt-mx-auto jlt-px-8">
                <div className="jlt-flex jlt-justify-between jlt-items-center jlt-h-full">
                    {notification?.image?.url ? (
                        <div>
                            <img
                                src={notification?.image?.url}
                                alt={notification?.image?.alt || "Offer Image"}
                                width={160}
                                height={78}
                                style={{
                                    height: "78px",
                                }}
                            />
                        </div>
                    ) : null}

                    {notification?.content ? (
                        <div
                            className="jlt-offer-wrapper"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(notification?.content),
                            }}
                        />
                    ) : null}

                    {notification?.title_subtitle ? (
                        <div
                            className="jlt-title-subtitle"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(notification?.title_subtitle),
                            }}
                        />
                    ) : null}

                    {notification?.notification_time ? (
                        <Counters date={dateToEndCountdown} />
                    ) : null}

                    {notification?.coupon_code ? (
                        <div className="jlt-text-center jlt-relative">
                            <h6 className="jlt jlt-text-white jlt-leading-5 jlt-font-semibold">
                                Use Code:
                            </h6>
                            <span className="jlt-text-xl jlt-text-[#01ffdc] jlt-font-semibold">
                                {notification?.coupon_code}
                            </span>
                            <CopyToClipboard
                                text={`${notification?.coupon_code}`}
                                onCopy={() => setCopiedText({ copied: true })}>
                                <button
                                    className={`jlt-absolute jlt-bottom-[6px] -jlt-right-[24px]`}>
                                    {copiedText?.copied ? (
                                        <Fragment>
                                            {getStyledIcon(getIcon("check"), "white")}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.33301 9.99998C2.71175 9.99998 2.40112 9.99998 2.1561 9.89849C1.82939 9.76316 1.56983 9.50359 1.4345 9.17689C1.33301 8.93186 1.33301 8.62124 1.33301 7.99998V3.46665C1.33301 2.71991 1.33301 2.34654 1.47833 2.06133C1.60616 1.81044 1.81014 1.60647 2.06102 1.47864C2.34624 1.33331 2.7196 1.33331 3.46634 1.33331H7.99967C8.62093 1.33331 8.93156 1.33331 9.17659 1.43481C9.50329 1.57013 9.76286 1.8297 9.89818 2.1564C9.99967 2.40143 9.99967 2.71206 9.99967 3.33331M8.13301 14.6666H12.533C13.2797 14.6666 13.6531 14.6666 13.9383 14.5213C14.1892 14.3935 14.3932 14.1895 14.521 13.9386C14.6663 13.6534 14.6663 13.28 14.6663 12.5333V8.13331C14.6663 7.38658 14.6663 7.01321 14.521 6.72799C14.3932 6.47711 14.1892 6.27314 13.9383 6.1453C13.6531 5.99998 13.2797 5.99998 12.533 5.99998H8.13301C7.38627 5.99998 7.0129 5.99998 6.72769 6.1453C6.4768 6.27314 6.27283 6.47711 6.145 6.72799C5.99967 7.01321 5.99967 7.38658 5.99967 8.13331V12.5333C5.99967 13.28 5.99967 13.6534 6.145 13.9386C6.27283 14.1895 6.4768 14.3935 6.72769 14.5213C7.0129 14.6666 7.38627 14.6666 8.13301 14.6666Z"
                                                    stroke="#fff"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </Fragment>
                                    )}
                                </button>
                            </CopyToClipboard>
                        </div>
                    ) : null}

                    <Link
                        className={`jlt-bg-gradient-to-r jlt-py-4 jlt-px-8 jlt-rounded-full jlt-text-sm jlt-font-semibold`}
                        style={{
                            color: notification?.btn_text_color,
                            background:
                                notification?.button_color === "gradient"
                                    ? `linear-gradient(${notification?.btn_gradinet_color_1}, ${notification?.btn_gradinet_color_2})`
                                    : notification?.btn_solid_color,
                        }}
                        href={notification?.button_url || "/#"}>
                        {notification?.button_text}
                    </Link>
                </div>
            </div>

            <style jsx>{`
                .jlt-offer-wrapper {
                    position: relative;
                    strong {
                        color: #fff;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        transform: rotate(-90deg);
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 12px;
                    }
                    h2 {
                        font-size: 72px;
                        color: #01ffdc;
                        line-height: 98px;
                        span:first-child {
                            font-weight: 900;
                        }
                        span:last-child {
                            font-size: 12px;
                            color: #ffffff;
                            font-weight: 600;
                            width: 40px;
                            height: 20px;
                            background: #f32f5e;
                            display: block;
                            padding: 2px 8px;
                            border-radius: 50px;
                            position: absolute;
                            top: 22px;
                            right: -30px;
                            line-height: 1.5;
                        }
                    }
                }
                .jlt-title-subtitle {
                    h2 {
                        font-size: 30px;
                        line-height: 36px;
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        text-shadow: 2px 0px #21f1f4;
                    }
                    p {
                        font-size: 13px;
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                    }
                }
            `}</style>
        </div>
    );
};
