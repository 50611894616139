import * as React from "react";
const SvgTwitter = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="white"
        className="bi bi-twitter-x jlt-w-[32px] jlt-h-[32px] jlt-bg-black jlt-rounded-full jlt-p-2"
        viewBox="0 0 16 16">
        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
    </svg>
);
export default SvgTwitter;

{
    /* <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38} height={38} rx={19} fill="#1DA1F2" />
    <path
      d="M27.065 14.864c.013.18.013.361.013.542 0 5.5-4.187 11.839-11.84 11.839-2.357 0-4.547-.683-6.39-1.868.335.039.657.051 1.005.051a8.334 8.334 0 0 0 5.166-1.777 4.169 4.169 0 0 1-3.89-2.886c.257.038.515.064.786.064.373 0 .747-.051 1.095-.142a4.162 4.162 0 0 1-3.337-4.083v-.052c.554.31 1.198.503 1.88.528A4.158 4.158 0 0 1 9.7 13.615c0-.773.206-1.482.566-2.1a11.827 11.827 0 0 0 8.58 4.354 4.697 4.697 0 0 1-.103-.953 4.16 4.16 0 0 1 4.162-4.161c1.198 0 2.28.502 3.04 1.314a8.191 8.191 0 0 0 2.64-1.005 4.15 4.15 0 0 1-1.828 2.293 8.34 8.34 0 0 0 2.396-.644 8.944 8.944 0 0 1-2.087 2.152Z"
      fill="#fff"
    />
  </svg> */
}
